import CallIcon from "@mui/icons-material/Call";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShareIcon from "@mui/icons-material/Share";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useParams } from "react-router-dom";
import { RWebShare } from "react-web-share";
import "../assets/style.css";

window.$(function () {
  var code = "+91";
  window.$("#txtPhone").val(code);
  window.$("#txtPhone").intlTelInput({
    autoHideDialCode: true,
    autoPlaceholder: "ON",
    dropdownContainer: document.body,
    formatOnDisplay: true,
    hiddenInput: "full_number",
    initialCountry: "auto",
    nationalMode: true,
    placeholderNumberType: "MOBILE",
    preferredCountries: ["US"],
    separateDialCode: true,
  });
});


function Home({ carddetails, homeApi, homecolor, facebook }) {
  const params = useParams();
  const [value, setValue] = useState();

  var emaildata = ""
  var address = ""
  var telephone = ""
  var whatsapp = ""
  var website = ""


  carddetails.map((data) => {
    if (data.type == "email") {
      emaildata = data.content

    }
    else if (data.type == "address") {
      address = data.content
    }
    else if (data.type == "tel") {
      telephone = data.content
    }
    else if (data.type == "wa") {
      whatsapp = data.content
    }
    else if (data.type == "url") {
      website = data.content
    }
  })

  console.log(whatsapp, telephone)
  console.log(facebook.facebook)

  let color = homecolor.color_code;
  let background = "";
  if (color) {
    background = `linear-gradient(to right, ${color}  6px, transparent 4px) 0 0,linear-gradient(to right, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${color}  6px, transparent 4px) 0 0,linear-gradient(to bottom, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to top, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to top, ${color}  6px, transparent 4px) 100% 100%`;
  }
  // let fname = homeApi?.name;
  // let title = homeApi?.title;
  // let sub_title = homeApi?.sub_title;
  // let email = homeApi?.email;
  // let phone = homeApi?.billing_phone;
  // let image = homeApi?.profile_image_src;

  const handleClick = () => {
    //     var file = new Blob(
    //       [
    //         `BEGIN:VCARD
    //     VERSION:3.0
    //     N:${fname};;;
    //     FN:${fname}
    //     TITLE:${title};
    //     EMAIL;type=INTERNET;type=pref:${email}
    //     TEL;type=MAIN:${sub_title}
    //     TEL;type=CELL;type=VOICE;type=pref:${phone}
    //     ADR;type=WORK;type=pref:;;;${image};;;
    //     END:VCARD
    // `,
    //       ],
    //       { type: "text/vcard;charset=utf-8" }
    //     );
    //     FileSaver.saveAs(
    //       file,
    //       `${fname}.pdf`,
    //       true
    //     );
  };

  return (
    typeof homeApi !== "undefined" && (
      <>
        <div
          id="home"
          className="m-5  col-lg-5 col-md-8 col-sm-10 col-xs-12 col-12 m-auto mb-3 borderdiv"
          style={{ backgroundColor: "white" }}
        >
          {background && (
            <div
              style={{
                background: background,
                backgroundRepeat: " no-repeat",
                backgroundSize: "60px 100px",
                backgroundColor: "white",
              }}
            >
              <div
                className="text-white w-25 my-auto px-1 text-center m-auto my-auto"
                style={{
                  backgroundColor: color,
                  borderRadius: "0 0px 12px 12px",
                }}
              >
                <VisibilityIcon fontSize="small" />
                Views : {homeApi.count}
              </div>

              <div className=" text-center py-5">
                <img
                  src={homeApi?.profile_image_src}
                  style={{
                    height: "150px",
                    maxWidth:"80%",
                    border: "1px black solid ",
                    borderRadius: "10px",
                  }}
                  alt=""
                />
              </div>
              <div className="fs-5 firmname text-center">{homeApi?.title}</div>
              <div
                className="m-auto my-3"
                style={{ width: "40%", backgroundColor: color, height: "2px" }}
              ></div>
              <div className="name m-auto text-center">
                {homeApi?.sub_title}
              </div>
              <div className=" row  my-4 mx-2">
                <div className="col-lg-3 px-lg-1 px-md-5 px-5 col-md-6 col-sm-6 my-2 col-12 text-center m-auto">
                  <button className="btn btn-secondary btn-sm rounded-pill homepagebutton ">
                    <CallIcon fontSize="small" />
                    {/* <small> */}
                    <a className="homeankertag" href={`tel:+1-+91-${telephone}`}>
                      &nbsp; Call
                    </a>
                    {/* </small> */}
                  </button>
                </div>
                <div className="col-lg-3 px-lg-0 px-md-5 px-5 col-md-6 col-sm-6 my-2 col-12 text-center m-auto">
                  <button className="btn btn-secondary btn-sm rounded-pill homepagebutton">
                    <WhatsAppIcon fontSize="small" />
                    {/* <small> */}
                    <a
                      className="homeankertag"
                      href={`https://wa.me/${whatsapp}?text=http://design.digitalesy.com/`}
                      target="_blank"
                    >
                      &nbsp; Contact
                    </a>
                    {/* </small> */}
                  </button>
                </div>
                <div className="col-lg-3 px-lg-1 px-md-5 px-5 col-md-6 col-sm-6 my-2 col-12 text-center  m-auto">
                  <button className="btn btn-secondary btn-sm rounded-pill homepagebutton">
                    <LocationOnIcon fontSize="small" />
                    {/* <small> */}
                    <a
                      className="homeankertag"
                      href={`http://maps.google.com/#!q=${address}`}
                      target="_blank"
                    >
                      &nbsp;Map
                    </a>
                    {/* </small> */}
                  </button>
                </div>

                <div className="col-lg-3 px-lg-1 px-md-5 px-5 col-md-6 col-sm-6 my-2 col-12 text-center  m-auto">
                  <button className="btn btn-secondary btn-sm rounded-pill homepagebutton">
                    <EmailIcon fontSize="small" />
                    {/* <small> */}
                    <a
                      className="homeankertag"
                      href={`mailto:${emaildata}`}
                      target="_blank"
                    >
                      &nbsp; Mail
                    </a>
                    {/* </small> */}
                  </button>
                </div>
              </div>
              <svg viewBox="0 0 400 25" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <pattern id="Wave" x="0" y="0" width="100" height="25" patternUnits="userSpaceOnUse">
                    <path d="M0 25 0 6C20 9 38 11 55 7 72 4 87 4 100 6l0 19z" id="path4" fill={color}></path>
                  </pattern>
                </defs>
                <rect width="100%" height="100%" fill="url(#Wave)"></rect>
              </svg>

              <div
                className="text-white p-3"
                style={{ backgroundColor: color }}
              >
                <table className="table-responsive">
                  <tbody>
                    <tr>
                      <td style={{ color: color }}>
                        <div className=" bg-white p-1">
                          <LocationOnIcon />
                        </div>
                      </td>
                      <td className="px-3 text-capitalize">
                        <a
                          className="homeankertag"
                          href={`http://maps.google.com/#!q=${address}`}
                          target="_blank"
                        >
                          {address}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table-responsive">
                  <tbody>
                    <tr>
                      <td style={{ color: color }}>
                        <div className=" bg-white p-1">
                          <EmailIcon />
                        </div>
                      </td>
                      <td className="px-3">
                        <a
                          className="homeankertag"
                          href={`mailto:${emaildata}`}
                          target="_blank"
                        >
                          {emaildata}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table-responsive">
                  <tbody>
                    <tr className="my-2">
                      <td style={{ color: color }}>
                        <div className=" bg-white  p-1">
                          <LanguageIcon />
                        </div>
                      </td>
                      <td className="px-3">
                        <a className="homeankertag" target="_blank" href={website}>
                          {website}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table-responsive">
                  <tr>
                    <td height="10" style={{ color: color }}>
                      <div className="bg-white p-1">
                        <CallIcon />
                      </div>
                    </td>
                    <td className="px-3">
                      <a className="homeankertag" href={`tel:${telephone}`} >
                        {telephone}
                      </a>

                    </td>
                  </tr>
                </table>
                <div>
                  <div className="row p-3 justify-content-around">
                    <div className="col-lg-5 col-md-8 py-auto  my-2 col-10 py-1 bg-white text-black text-center">
                      <div className="main">
                        <div className="form_group">
                          <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="IN"
                            name="number"
                            value={value}
                            onChange={setValue}
                          />
                          {/* <input type="tel" id="txtPhone" name='number' value={digidata.number} placeholder='Enter WhatsApp No. ' onChange={handlemobileno} className="txtbox" /> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-8 col-10 my-2 py-1"
                      style={{ backgroundColor: "#51B14D" }}
                    >
                      <a
                        href={`https://wa.me/${value}?text=http://design.digitalesy.com`}
                        className="whatsapp_float homeankertag"
                        target="_blank"
                        rel={`https://design.digitalesy.com/${homeApi?.slug}`}
                        style={{ width: "100%" }}
                      >
                        <WhatsAppIcon /> <small> Share On WhatsApp </small>
                      </a>
                    </div>
                  </div>
                  <div className="row py-3 justify-content-around">
                    <div
                      className="col-lg-5 col-md-8 col-10 pr-2 p-2 text-center my-2"
                      style={{
                        backgroundColor: color,
                        border: `6px solid white`,
                      }}
                    >
                      <a
                        className="homeankertag"
                        href="https://vcard-bucket.s3.us-east-2.amazonaws.com/A8/156/156.vcf"
                        download
                        target="_blank"
                      >
                        <DownloadIcon /> Add to Phone Book
                      </a>
                    </div>
                    <RWebShare
                      data={{
                        text: "For website like this ",
                        url: `https://design.digitalesy.com/${homeApi?.slug}`,
                        title: "My Digital card website",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <div
                        className="col-lg-5 col-md-8 col-10 p-2 text-center my-2"
                        style={{
                          backgroundColor: color,
                          border: `6px solid white`,
                        }}
                      >
                        <ShareIcon /> Share
                      </div>
                    </RWebShare>
                  </div>
                  <div className="row py-3 justify-content-center">
                    <div
                      className="col-lg-10 col-md-8 col-10 pr-2 p-2 text-center "
                      style={{
                        backgroundColor: color,
                        border: `6px solid white`,
                        color: " #008ad3 "
                      }}
                    >
                      <a className="homeankertag" href="" onClick={handleClick} target="_blank">
                        <CloudDownloadIcon /> Save Card
                      </a>
                    </div>
                  </div>
                  <div className="text-end pr-3 mt-5" style={{ color: " #008ad3 " }}>
                    <a href={`${facebook.facebook}`} target="_blank"><img style={{ height: "40x", width: "40px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Facebook_circle_pictogram.svg/1200px-Facebook_circle_pictogram.svg.png" alt="" /></a>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          )}
        </div>
      </>
    )
  );
}

export default Home;