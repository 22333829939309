import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from '../App'

function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='/' element={<Default/>}/> */}
        {/* {appConfig.apiUrl.includes('saraup') ? <Route exact path="/" element={<App />}></Route> :  */}
        <Route exact path="/:id" element={<App />}></Route>
        {/* } */}

        {/* <Route path="*" element={<Default />} /> */}
      </Routes>
    </BrowserRouter>
  )
}
export default Routing