import DownloadIcon from '@mui/icons-material/Download';
import * as React from 'react';


export default function MediaCard({ productApi, homecolor }) {
    console.log(productApi)
    let imageurl = ""
    let color = homecolor.color_code;
    let background = '';
    if (color) {
        background = `linear-gradient(to right, ${color}  6px, transparent 4px) 0 0,linear-gradient(to right, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${color}  6px, transparent 4px) 0 0,linear-gradient(to bottom, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to top, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to top, ${color}  6px, transparent 4px) 100% 100%`
    };
    return (
        <div id='product' className='m-5  col-lg-5 col-md-8 col-sm-10 col-xs-12 col-12 m-auto my-3 borderdiv' style={{ backgroundColor: "white" }}>
            {background && <div className='p-3 pb-4' style={{
                background: background,
                backgroundRepeat: " no-repeat",
                backgroundSize: "60px 100px",
                backgroundColor: "white"
            }}>
                <div className='row m-auto w-lg-50 w-100'>
                    <div className='w-lg-50 m-auto fs-1 fw-bold' style={{ color: color }}>
                        Product
                    </div>
                    {
                        productApi?.map((service, key) => (
                            <div key={key}>
                                <div className=' col-10 m-auto border my-2' key={key}>
                                    <div className='text-end my-3'>
                                        <button style={{ backgroundColor: color, border: "2px solid white", color: "white" }}>
                                            <a className='homeankertag' href={service?.service_image_src} target="_blank" download>
                                                <DownloadIcon fontSize='small' />Download product card
                                            </a>
                                        </button>
                                    </div>
                                    <div className='fs-4 my-2 px-2'>
                                    <strong>{key+1}. {service.service_name} </strong>
                                    </div>
                                    <img src={service.service_image_src} className="w-75 " style={{ height: "auto" }}
                                        alt="" />
                                        <div className='my-2 px-2'>
                                        {service.service_description}
                                    </div>
                                    <div className='text-end my-3'>
                                        <button className='px-3 py-1' style={{ backgroundColor: color, border: "2px solid white", color: "white" }}><a className='homeankertag' href="https://wa.me/+919983406376?text='http://design.digitalesy.com/'">Enquiry </a></button>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>}
        </div>
    );
}
