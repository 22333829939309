import { Button, Typography } from "@mui/material";
import Lottie from "react-lottie";
import animationDataVendorNotFound from "../assets/animations/91191-404-notfound.json";
export default function NotFound() {
  let color = "#FF7300";
  let background = "";
  const defaultOptionsVendorNotFound = {
    loop: true,
    autoplay: true,
    animationData: animationDataVendorNotFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  if (color) {
    background = `linear-gradient(to right, ${color}  6px, transparent 4px) 0 0,linear-gradient(to right, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${color}  6px, transparent 4px) 0 0,linear-gradient(to bottom, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to top, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to top, ${color}  6px, transparent 4px) 100% 100%`;
  }

  return (
    <>
      <div
        id="home"
        className="m-5  col-lg-5 col-md-8 col-sm-10 my-5 col-xs-12 col-12 m-auto mb-3 borderdiv"
        style={{ backgroundColor: "white" }}
      >
        {background && (
          <div
            style={{
              background: background,
              backgroundRepeat: " no-repeat",
              backgroundSize: "60px 100px",
              backgroundColor: "white",
            }}
            className="container"
          >
            <div className="row pb-4">
              <div className="col-12">
                <Lottie
                  options={defaultOptionsVendorNotFound}
                  height={230}
                  width={280}
                />
              </div>
              <div className="col-12 py-2 text-center">
                <Typography
                  component={"h4"}
                  variant="h4"
                  className=" text-danger"
                >
                  <b>Vcard Not Found</b>
                </Typography>
              </div>
              <div className="col-12 text-center py-2">
                <Typography>
                  Create your own Digital Vcard on SaraUp now.  <Typography
                  component={"h4"}
                  variant="h6"
                  color="primary"
                ><b>Download App now!</b></Typography>
                </Typography>
              </div>
              <div className="col-12 text-center py-2">
              <div className="d-flex">
          <div className="flex-1">
            <a href='https://play.google.com/store/apps/details?id=com.saraup' target={'_blank'}> <Button><img src={require("../assets/images/Playstore.png")} className='cardImage' /></Button></a>
          </div>
          <div className="flex-1">
            <a href='https://apps.apple.com/us/app/saraup/id1578229036' target={'_blank'}><Button><img src={require("../assets/images/Appstorenew.png")} className=' cardImage' />

            </Button></a>
          </div>
        </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
