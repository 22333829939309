import axios from 'axios'
import React, { useState } from 'react'
import { appConfig } from '../config/appconfig'

function EnquiryForm({ EnquiryFormApi, homecolor }) {




    const handlechangeevent = (event) => {
        setFormdata({
            ...formdata, [event.target.name]: event.target.value
        })
        // if (event.target.name == "card_id") {
        //     console.log("hello")
        //     console.log(event.target.value)
        //     setFormdata(event.target.value == data)
        //     console.log(formdata)
        // }
    }


    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const [formdata, setFormdata] = useState({
        name: "",
        phone: "",
        email: "",
        message: "",
    })


    const handleenquiry = (event) => {


        // POST request using fetch inside useEffect React hook
        //Obj of data to send in future like a dummyDb
        // const data = { username: 'example' };

        //POST request with body equal on data in JSON format
        const data = EnquiryFormApi?.card_id
        let _form = new FormData();
        _form.append('card_id', data)
        _form.append('name', formdata.name)
        _form.append('email', formdata.email)
        _form.append('message', formdata.message)
        _form.append('phone', formdata.phone)
        axios.post(`${appConfig.apiUrl}enquiry`, _form, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((response) => {
                setSuccess(true)
                setError(false)

            })

            .catch((error) => {
                throw (setError(true),
                    setSuccess(false))
            })
    }
    let color = homecolor.color_code;
    let background = '';
    if (color) {
        background = `linear-gradient(to right, ${color}  6px, transparent 4px) 0 0,linear-gradient(to right, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${color}  6px, transparent 4px) 0 0,linear-gradient(to bottom, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to top, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to top, ${color}  6px, transparent 4px) 100% 100%`
    };

    return (
        <>
            <div id='enquiryform' className='m-5  col-lg-5 col-md-8 col-sm-10 col-xs-12 col-12 m-auto my-3 mb-2 borderdiv' style={{ backgroundColor: "white" }}>
                {background && <div style={{
                    background: background,
                    backgroundRepeat: " no-repeat",
                    backgroundSize: "60px 100px",
                    backgroundColor: "white"
                }}>
                    <div className='p-5'>
                        <div className='fs-3 mb-3' style={{ color: color, fontWeight: "500" }}>
                            ENQUIRY FORM
                        </div>
                        {/* <form> */}
                        <div className="row mb-lg-4 mb-md-4 mb-sx-4 mb-xs-2">
                            <div className="form-outline mb-xs-2">
                                <input type="text" id="form3Example1" onChange={handlechangeevent} name='name' value={formdata.name} placeholder='Full name' className="form-control" />
                            </div>
                        </div>
                        <div className="row mb-lg-4 mb-md-4 mb-sx-4 mb-2">
                            <div className="col-lg-6 my-2 col-md-6 col-ms-6 col-12">
                                <div className="form-outline">
                                    <input type="text" id="form3Example1" onChange={handlechangeevent} name='phone' value={formdata.phone} placeholder='Phone Number' className="form-control" />

                                </div>
                            </div>
                            <div className="col-lg-6 my-2 col-md-6 col-ms-6 col-12">
                                <div className="form-outlinemb-lg-3 mb-md-3 mb-sx-3 mb-1">
                                    <input type="email" id="form3Example3" onChange={handlechangeevent} name='email' value={formdata.email} placeholder='Enter Email' className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-lg-3 mb-md-3 mb-sx-3 mb-2">
                            <div className="form-outline ">
                                <textarea id="w3reviewform3Example3" onChange={handlechangeevent} name='message' value={formdata.message} placeholder='Enter Message' rows="3" cols="50" className="form-control"> </textarea>
                            </div>
                        </div>

                        <button type="submit" onClick={handleenquiry} className="btn px-3 btn-block mb-4 text-white" style={{ backgroundColor: color }}>Send</button>
                        {/* </form> */}
                        <div style={{ color: "green" }}>
                            {success ? "Subbmitted Successfully" : ""}
                        </div>
                        <div style={{ color: "red" }}>
                            {error ? "Error in Submission" : ""}
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
}

export default EnquiryForm