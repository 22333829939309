import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import * as React from 'react';
import { useState } from 'react';
import '../assets/caro.css';

import '../assets/style.css';

export default function MasonryImageList({ imageApi, homecolor }) {

  const [imageid, setImageid] = useState(0)
  const [modalImage, setModalImage] = useState()


  const showimage = (ele) => {

    setImageid(ele)
    setModalImage(imageApi[ele].gallery_image_src)
  }

  const Nextbutton = () => {
    setImageid(imageid + 1)

    setModalImage(imageApi[imageid].gallery_image_src)
    if (imageid >= (imageApi.length) - 1) {
      setImageid(0)
      setModalImage(imageApi[imageid].gallery_image_src)
    }
  }
  const previousbutton = () => {
    setImageid(imageid + 1)
    setModalImage(imageApi[imageid].gallery_image_src)
    if (imageid = 0) {
      setImageid((imageApi.length) - 1)
      setModalImage(imageApi[imageid].gallery_image_src)
    }
  }
  let color = homecolor.color_code;
  let background = '';
  if (color) {
    background = `linear-gradient(to right, ${color}  6px, transparent 4px) 0 0,linear-gradient(to right, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${color}  6px, transparent 4px) 0 0,linear-gradient(to bottom, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to top, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to top, ${color}  6px, transparent 4px) 100% 100%`
  };
  return (
    <>
      <div id='gallery' className='m-5 col-lg-5 col-md-8 col-sm-10 col-xs-12 col-12 m-auto ' style={{ backgroundColor: "white" }}>
        {background && <div className='p-3' style={{
          background: background,
          backgroundRepeat: " no-repeat",
          backgroundSize: "60px 100px",
          backgroundColor: "white"
        }}>
          <div style={{ marginTop: "20px" }}>
            <div className='container fw-bold fs-1 m-auto pb-2 pl-2 ml-2 w-lg-50 w-100' style={{ color: color, marginBottom: "25px", marginTop: "15px" }}>Gallery</div>
          </div>
          <div className='my-3'>
            <Box sx={{ width: "auto", height: " auto", overflowY: 'scroll' }}>
              <ImageList variant="masonry" cols={3} gap={10}>
                {imageApi?.map((item, key) => (
                  <ImageListItem key={item.img}>
                    <img
                      src={`${item.gallery_image_src}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.gallery_image_src}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.title}
                      loading="lazy"
                      data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                      onClick={() => {
                        showimage(key)
                      }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          </div>

          <div>
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog" style={{ width: "400px", margin: "auto", height: "400px!important" }}>
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="rounded-circle border btn btn-secondary btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <ImageListItem>
                    <img src={modalImage} alt="" style={{ height: "400px", width: "350px", marginLeft: "22px", borderRadius: "22px" }} />
                    <div className='buttonmodal'>
                      <div className='buttonmodal1'> <button className='btn text-secondary rounded-circle' onClick={previousbutton} ><KeyboardDoubleArrowLeftIcon fontSize='large' /> </button></div>
                      <div className='buttonmodal2'> <button className='btn text-secondary rounded-circle' onClick={Nextbutton}><KeyboardDoubleArrowRightIcon fontSize='large' /></button></div>
                    </div>
                  </ImageListItem>
                  <div className="modal-footer m-auto w-100">

                    <div className='d-flex flex-row  w-100 justify-content-between'>
                      <div> <button className='btn btn-info px-3 rounded-pill' onClick={previousbutton}>Prev</button></div>
                      <div> <button className='btn btn-info px-3 rounded-pill' onClick={Nextbutton}>Next</button></div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </>
  );
}