import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, Toolbar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import * as React from 'react';
import { useState } from 'react';
import '../assets/caro.css';
import '../assets/style.css';


export default function Gallery({ data, homecolor }) {

  const [imageid, setImageid] = useState(0)
  const [onModal, setModal] = useState(false);

  let color = homecolor.color_code;
  let background = '';
  if (color) {
    background = `linear-gradient(to right, ${color}  6px, transparent 4px) 0 0,linear-gradient(to right, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${color}  6px, transparent 4px) 0 0,linear-gradient(to bottom, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to top, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to top, ${color}  6px, transparent 4px) 100% 100%`
  };
  const handleModalClose = () => {
    setModal(false)
  }
  return (
    <>
      <Dialog open={onModal} onClose={handleModalClose} fullWidth maxWidth="sm">
        <AppBar position="static" elevation={1} className='modal-header align-items-start'>
          <Toolbar className="flex w-full">
            <Typography component={'span'} variant={'body2'} color="inherit" className='fs-5'>
              {imageid.title}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent classes={{ root: 'p-20' }}>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="p-16 sm:p-24">
              <div className="grid gap-10 lg:grid-cols-1">
                {imageid !== 0 && <video className='video-section-modal' autoPlay controls>
                  <source src={imageid.FileSrc} type="video/mp4" />
                  <source src={imageid.FileSrc} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="normal-case whitespace-no-wrap modal-header"
            variant="contained"
            onClick={handleModalClose}
            color="primary"
            type="button"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div id='video' className='m-5 mt-3 col-lg-5 col-md-8 col-sm-10 col-xs-12 col-12 m-auto ' style={{ backgroundColor: "white" }}>
        {background && <div className='p-3' style={{
          background: background,
          backgroundRepeat: " no-repeat",
          backgroundSize: "60px 100px",
          backgroundColor: "white"
        }}>
          <div style={{ marginTop: "20px" }}>
            <div className='container fw-bold fs-1 m-auto pb-2 pl-2 ml-2 w-lg-50 w-100' style={{ color: color, marginBottom: "25px", marginTop: "15px" }}>Videos</div>
          </div>
          <div className='my-3'>
            <Box sx={{ width: "auto", height: " auto", overflowY: 'scroll' }}>
              <ImageList variant="masonry" cols={2} gap={20}>
                {data.videos?.map((item, key) => (
                  <ImageListItem key={key} gap={20} className='video-gallery' onClick={() => {
                    setImageid(item);
                    setModal(true)
                  }}
                  >
                    {item.FileSrc !== '' && item.FileSrc !== null &&
                      <>
                        <video className='videos-section'  >
                          <source src={item.FileSrc} type="video/mp4" />
                          <source src={item.FileSrc} type="video/ogg" />
                          Your browser does not support the video tag.
                        </video>
                        <img className='video-playicon' src='https://icon-library.com/images/play-icon-transparent-background/play-icon-transparent-background-22.jpg' />
                        <div className='my-2 text-center'>
                          <Typography component={'span'} variant={'body1'} color="inherit" className=' text'>
                            {item.title}
                          </Typography>
                        </div>
                      </>
                    }
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          </div>
        </div>}
      </div>
    </>
  );
}
