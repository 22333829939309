import React from 'react';



function Payments({ paymentApi, homecolor }) {

  let color = homecolor.color_code;
  let background = '';
  if (color) {
    background = `linear-gradient(to right, ${color}  6px, transparent 4px) 0 0,linear-gradient(to right, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${color}  6px, transparent 4px) 0 0,linear-gradient(to bottom, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to top, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to top, ${color}  6px, transparent 4px) 100% 100%`
  };
  return (
    <div id='payment' className='m-5  col-lg-5 col-md-8 col-sm-10 col-xs-12 col-12 m-auto my-3 borderdiv' style={{ backgroundColor: "white" }}>
      {background && <div className='p-3 px-4' style={{
        background: background,
        backgroundRepeat: " no-repeat",
        backgroundSize: "60px 70px",
        backgroundColor: "white"
      }}>
        <div className='fs-2 fw-bold' style={{ color: color }}>
          Payments
        </div>
        <div className='my-3 row'>
          {paymentApi?.map((contents, key) => (
            <div className='col-6' key={key}>
              <div className='my-2 fw-bold' >{contents.content}</div>
              <div className=' fs-6'> QR Codes: {contents.label} </div>
              <div >
                <img src={paymentApi?.payment_image_src} style={{ height: "350px", width: "270px" }} alt="image" />
              </div>
            </div>
          ))}
        </div>
        <div>
        </div>
      </div>}
    </div>
  )
}

export default Payments