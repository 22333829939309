import CallIcon from "@mui/icons-material/Call";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShareIcon from "@mui/icons-material/Share";
import StarIcon from "@mui/icons-material/Star";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useParams } from "react-router-dom";
import { RWebShare } from "react-web-share";
import "../assets/style.css";
import { appConfig } from "../config/appconfig";
window.$(function () {
  var code = "+91";
  window.$("#txtPhone").val(code);
  window.$("#txtPhone").intlTelInput({
    autoHideDialCode: true,
    autoPlaceholder: "ON",
    dropdownContainer: document.body,
    formatOnDisplay: true,
    hiddenInput: "full_number",
    initialCountry: "auto",
    nationalMode: true,
    placeholderNumberType: "MOBILE",
    preferredCountries: ["US"],
    separateDialCode: true,
  });
});

function Home({ carddetails, homeApi, homecolor, facebook, home }) {
  const params = useParams();
  const [show, setShow] = useState(false);
  const [value, setValue] = useState();
  var emaildata = "";
  var address = "";
  var telephone = "";
  var whatsapp = "";
  var website = "";

  carddetails.map((data) => {
    if (data.type == "email") {
      emaildata = data.content;
    } else if (data.type == "address") {
      address = data.content;
    } else if (data.type == "tel") {
      telephone = data.content;
    } else if (data.type == "wa") {
      whatsapp = data.content;
    } else if (data.type == "url") {
      website = data.content;
    }
  });
  const handleChange = (e) => {
    if (e !== undefined) {
      setShow(true);
    } else {
      setShow(false);
    }
    setValue(e);
  };

  let color = homecolor.color_code;
  let background = "";
  if (color) {
    background = `linear-gradient(to right, ${color}  6px, transparent 4px) 0 0,linear-gradient(to right, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${color}  6px, transparent 4px) 0 0,linear-gradient(to bottom, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to top, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to top, ${color}  6px, transparent 4px) 100% 100%`;
  }

  return (
    typeof homeApi !== "undefined" && (
      <>
        <div
          id="home"
          className="m-5  col-lg-5 col-md-8 col-sm-10 col-xs-12 col-12 m-auto mb-3 borderdiv"
          style={{ backgroundColor: "white" }}
        >
          {background && (
            <div
              style={{
                background: background,
                backgroundRepeat: " no-repeat",
                backgroundSize: "60px 100px",
                backgroundColor: "white",
              }}
            >
              <div
                className="text-white w-25 my-auto px-1 text-center m-auto my-auto"
                style={{
                  backgroundColor: color,
                  borderRadius: "0 0px 12px 12px",
                }}
              >
                <VisibilityIcon fontSize="small" />
                Views : {homeApi.count}
              </div>

              <div
                className=" text-center pt-4 pb-3"
                style={{
                  background: `url('${homeApi.cover_image}')`,
                  backgroundSize: " 100% 100%",
                  backgroundRepeat: " no-repeat",
                  margin: "30px",
                  minHeight: "200px",
                  position: "relative",
                  marginTop: "2px",
                }}
              >
                <img
                  src={homeApi?.profile_image_src}
                  style={{
                    height: "150px",
                    width: "150px",
                    border: "1px black solid ",
                    borderRadius: "100%",
                    position: " absolute",
                    left: 0,
                    right: 0,
                    bottom: "-55px",
                    margin: " 0 auto",
                  }}
                  alt=""
                />
              </div>
              <div className="fs-5 fw-bold firmname text-center">{homeApi?.title}</div>
              <div
                className="m-auto my-3"
                style={{ width: "40%", backgroundColor: color, height: "2px" }}
              ></div>
              <div className="name m-auto text-center">
                {homeApi?.sub_title}
              </div>
              <div className="text-center">
                <Rating
                  name="rating"
                  value={Math.round(home.review_count)}
                  max={Math.round(home.avg_review)}
                  readOnly
                  precision={1}
                  // getLabelText={getLabelText/}
                  // onChange={(event, newValue) => {
                  //   setValue(newValue);
                  // }}
                  // onClick={hangleChange}
                  // onChangeActive={(event, newHover) => {
                  //   setHover(newHover);
                  // }}

                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} size="small" />
                  }
                />
              </div>
              <div className=" row  my-4 mx-2">
                {homeApi.phone_number && (
                  <div className="col-lg-3 px-lg-1 px-md-5 px-5 col-md-6 col-sm-6 my-2 col-12 text-center m-auto">
                    <button className="btn btn-secondary btn-sm rounded-pill homepagebutton ">
                      <CallIcon fontSize="small" />
                      {/* <small> */}
                      <a
                        className="homeankertag"
                        href={`tel:+91-${homeApi.phone_number}`}
                      >
                        &nbsp; Call
                      </a>
                      {/* </small> */}
                    </button>
                  </div>
                )}
                {homeApi.phone_number && (
                  <div className="col-lg-3 px-lg-0 px-md-5 px-5 col-md-6 col-sm-6 my-2 col-12 text-center m-auto">
                    <button className="btn btn-secondary btn-sm rounded-pill homepagebutton">
                      <WhatsAppIcon fontSize="small" />
                      {/* <small> */}
                      <a
                        className="homeankertag"
                        href={`https://wa.me/${homeApi.phone_number}`}
                        target="_blank"
                      >
                        &nbsp; Contact
                      </a>
                      {/* </small> */}
                    </button>
                  </div>
                )}
                <div className="col-lg-3 px-lg-1 px-md-5 px-5 col-md-6 col-sm-6 my-2 col-12 text-center  m-auto">
                  <button className="btn btn-secondary btn-sm rounded-pill homepagebutton">
                    <LocationOnIcon fontSize="small" />
                    {/* <small> */}
                    <a
                      className="homeankertag"
                      href={`http://maps.google.com/#!q=${homeApi.address}`}
                      target="_blank"
                    >
                      &nbsp;Map
                    </a>
                    {/* </small> */}
                  </button>
                </div>

                {homeApi.email && (
                  <div className="col-lg-3 px-lg-1 px-md-5 px-5 col-md-6 col-sm-6 my-2 col-12 text-center  m-auto">
                    <button className="btn btn-secondary btn-sm rounded-pill homepagebutton">
                      <EmailIcon fontSize="small" />
                      {/* <small> */}
                      <a
                        className="homeankertag"
                        href={`mailto:${homeApi.email}`}
                        target="_blank"
                      >
                        &nbsp; Mail
                      </a>
                      {/* </small> */}
                    </button>
                  </div>
                )}
              </div>
              <svg viewBox="0 0 400 25" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <pattern
                    id="Wave"
                    x="0"
                    y="0"
                    width="100"
                    height="25"
                    patternUnits="userSpaceOnUse"
                  >
                    <path
                      d="M0 25 0 6C20 9 38 11 55 7 72 4 87 4 100 6l0 19z"
                      id="path4"
                      fill={color}
                    ></path>
                  </pattern>
                </defs>
                <rect width="100%" height="100%" fill="url(#Wave)"></rect>
              </svg>

              <div
                className="text-white p-3"
                style={{ backgroundColor: color }}
              >
                <table className="table-responsive">
                  <tbody>
                    <tr>
                      <td style={{ color: color }}>
                        <div className=" bg-white p-1">
                          <LocationOnIcon />
                        </div>
                      </td>
                      <td className="px-3 text-capitalize">
                        <a
                          className="homeankertag"
                          href={`http://maps.google.com/#!q=${
                            address ? address : homeApi.address
                          }`}
                          target="_blank"
                        >
                          {address ? address : homeApi.address}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table-responsive">
                  <tbody>
                    {homeApi.email && (
                      <tr>
                        <td style={{ color: color }}>
                          <div className=" bg-white p-1">
                            <EmailIcon />
                          </div>
                        </td>
                        <td className="px-3">
                          <a
                            className="homeankertag"
                            href={`mailto:${homeApi.email}`}
                            target="_blank"
                          >
                            {homeApi.email}
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <table className="table-responsive">
                  {website && (
                    <tbody>
                      <tr className="my-2">
                        <td style={{ color: color }}>
                          <div className=" bg-white  p-1">
                            <LanguageIcon />
                          </div>
                        </td>
                        <td className="px-3">
                          <a
                            className="homeankertag"
                            target="_blank"
                            href={website}
                          >
                            {website}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                <table className="table-responsive">
                  <tbody>
                    {homeApi.phone_number && (
                      <tr>
                        <td height="10" style={{ color: color }}>
                          <div className="bg-white p-1">
                            <CallIcon />
                          </div>
                        </td>
                        <td className="px-3">
                          <a
                            className="homeankertag"
                            href={`tel:+91-${homeApi.phone_number}`}
                          >
                            {homeApi.phone_number}
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div>
                  <div className="row p-3 justify-content-around">
                    <div className="col-lg-5 col-md-8 py-auto  my-2 col-10 py-1 bg-white text-black text-center">
                      <div className="main">
                        <div className="form_group">
                          <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="IN"
                            name="number"
                            value={value}
                            onChange={handleChange}
                          />
                          {/* <input type="tel" id="txtPhone" name='number' value={digidata.number} placeholder='Enter WhatsApp No. ' onChange={handlemobileno} className="txtbox" /> */}
                        </div>
                      </div>
                    </div>

                    {show ? (
                      <div
                        className="col-lg-6 col-md-8 col-10 my-2 py-1"
                        style={{ backgroundColor: "#51B14D" }}
                      >
                        <a
                          href={` https://wa.me/${value}?text=${homeApi.title}%0aDigital Vcard by SaraUp %0aView My Vcard: ${appConfig.shareUrl}/${homeApi.slug} %0aMake your own free Vcard with SaraUp: https://play.google.com/store/apps/details?id=com.saraup`}
                          className="whatsapp_float homeankertag"
                          target="_blank"
                          rel={`${appConfig.shareUrl}/${homeApi?.slug}`}
                          style={{ width: "100%" }}
                        >
                          <WhatsAppIcon /> <small> Share On WhatsApp </small>
                        </a>
                      </div>
                    ) : (
                      <div
                        className="col-lg-6 col-md-8 col-10 my-2 py-1"
                        style={{
                          backgroundColor: "#51B14D",
                          cursor: "not-allowed",
                        }}
                      >
                        <WhatsAppIcon /> <small> Share On WhatsApp </small>
                      </div>
                    )}
                  </div>
                  <div className="container">
                    <div className="row py-3">
                      <div className="col-lg-6 text-center">
                        {homeApi?.qr_image !== "" && (
                          <img
                            src={homeApi?.qr_image}
                            style={{
                              // height: "220px",
                              // width: "100%",
                              borderRadius: "10px",
                              marginBottom: "3px",
                            }}
                            alt=""
                          />
                        )}
                        <div className="text-center pt-2">
                          <Typography
                            component={"span"}
                            variant={"body2"}
                            color="inherit"
                           
                            className=" fw-bold saraupshare text"
                          >
                            Scan with SaraUp app
                          </Typography>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div
                          className=" pr-2 p-2 text-center my-2"
                          style={{
                            backgroundColor: color,
                            border: `6px solid white`,
                          }}
                        >
                          <div
                            className="homeankertag "
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              window.open(
                                `${appConfig.apiUrl}downloadVCF/${homeApi.slug}`,
                                "_blank"
                              );
                            }}
                          >
                            <DownloadIcon /> Add to Phone Book
                          </div>
                        </div>
                        <RWebShare
                          data={{
                            text: `View my business ${homeApi.title} digital card on SaraUp.`,
                            url: `${appConfig.shareUrl}/${homeApi?.slug}`,
                            title: "My Digital card website",
                          }}
                          onClick={() => console.log("shared successfully!")}
                        >
                          <div
                            className=" p-2 text-center my-2"
                            style={{
                              backgroundColor: color,
                              border: `6px solid white`,
                              cursor: "pointer",
                            }}
                          >
                            <ShareIcon /> Share
                          </div>
                        </RWebShare>
                        <div className="d-flex justify-content-lg-start justify-content-center">
                          {homeApi.whatsapp_link !== "" &&
                            homeApi.whatsapp_link !== null && (
                              <div
                                className="text-end pr-3 mr-3 mx-3"
                                style={{ color: " #008ad3 " }}
                              >
                                <a
                                  href={`${homeApi.whatsapp_link}`}
                                  target="_blank"
                                >
                                  <img
                                    style={{ height: "40x", width: "40px" }}
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png"
                                    alt=""
                                  />
                                </a>
                              </div>
                            )}
                          {homeApi.twitter_link !== "" &&
                            homeApi.twitter_link !== null && (
                              <div
                                className="text-end  mt-3 "
                                style={{ color: " #008ad3 " }}
                              >
                                <a
                                  href={`${homeApi.twitter_link}`}
                                  target="_blank"
                                >
                                  <img
                                    style={{ height: "40x", width: "40px" }}
                                    src="https://www.pngkey.com/png/full/2-27646_twitter-logo-png-transparent-background-logo-twitter-png.png"
                                    alt=""
                                  />
                                </a>
                              </div>
                            )}
                          {homeApi.facebook_link !== "" &&
                            homeApi.facebook_link !== null && (
                              <div
                                className="text-end pr-3 mx-3 mt-3"
                                style={{ color: " #008ad3 " }}
                              >
                                <a
                                  href={`${homeApi.facebook_link}`}
                                  target="_blank"
                                >
                                  <img
                                    style={{ height: "40x", width: "40px" }}
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Facebook_circle_pictogram.svg/1200px-Facebook_circle_pictogram.svg.png"
                                    alt=""
                                  />
                                </a>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row py-3 justify-content-center">
                    <div
                      className="col-lg-10 col-md-8 col-10 pr-2 p-2 text-center "
                      style={{
                        backgroundColor: color,
                        border: `6px solid white`,
                        color: " #008ad3 "
                      }}
                    >
                      <a className="homeankertag" href="" onClick={handleClick} target="_blank">
                        <CloudDownloadIcon /> Save Card
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
              <div></div>
            </div>
          )}
        </div>
      </>
    )
  );
}

export default Home;
