import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import axios from 'axios';
import React, { useState } from 'react';
import '../assets/style.css';
import { appConfig } from '../config/appconfig';

const labels = {
  1: 'Poor',
  2: 'Average',
  3: 'Good',
  4: 'VeryGood',
  5: 'Excellent',
};
function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}


function Feedback({ feedbackApi, homecolor }) {


  const [value, setValue] = useState(2);
  const [hover, setHover] = useState(-1);

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const [fBack, setFBack] = useState({
    rating: "",
    name: "",
    comment: "",
  })

  const handlefedbacks = (event) => {

    const data = feedbackApi[0]?.card_id

    let _formdata = new FormData();
    _formdata.append('card_id', data)
    _formdata.append('name', fBack.name)
    _formdata.append('comment', fBack.comment)
    _formdata.append('rating', fBack.rating)

    axios.post(`${appConfig.apiUrl}feedbacks/create`, _formdata, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    })
      .then((response) => {
        setSuccess(true)
        setError(false)

      })

      .catch((error) => {
        throw (setError(true),
          setSuccess(false))
      })
  }

  const data = feedbackApi?.card_id
  const hangleChange = (event) => {
    setFBack({
      ...fBack, [event.target.name]: event.target.value
    })
    if (event.target.name == "card_id") {
      setFBack(event.target.value == data)
    }
  }

  let color = homecolor.color_code;
  let background = '';
  if (color) {
    background = `linear-gradient(to right, ${color}  6px, transparent 4px) 0 0,linear-gradient(to right, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${color}  6px, transparent 4px) 0 0,linear-gradient(to bottom, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to top, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to top, ${color}  6px, transparent 4px) 100% 100%`
  };
  return (
    <>

      <div id='feedback' className='m-5  col-lg-5 col-md-8 col-sm-10 col-xs-12 col-12 m-auto my-3 mb-2 borderdiv' style={{ backgroundColor: "white" }}>
        {background && <div style={{
          background: background,
          backgroundRepeat: " no-repeat",
          backgroundSize: "60px 100px",
          backgroundColor: "white"
        }}>
          <div className='p-5'>
            <div className=''>
              {
                feedbackApi?.map((feeds, key) => (
                  <div key={key}>
                    <div className='row border-bottom-5 border-dark' style={{ fontSize: "15px", color: color }}>
                      {feeds.name}
                    </div>
                    <div className='row'>
                      <Rating name="size-large" readOnly defaultValue={Number(feeds.rating)} size="large" />

                    </div>
                    <div className='row'>
                      <strong>{feeds.comment}</strong>
                    </div>
                    <hr />
                  </div>
                ))
              }
            </div>
            <div className='fs-3 mb-3' style={{ color: color, fontWeight: "500" }}>
              FEEDBACKS
            </div>
            {/* <form> */}
            <div className="row mb-4">
              <div className="form-outline">
                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name="rating"
                    value={Number(fBack.rating)}
                    precision={1}
                    getLabelText={getLabelText}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    onClick={hangleChange}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}

                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} size="small" />}
                  />
                  {value !== null && (
                    <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
                  )}
                </Box>
              </div>
            </div>
            <div className="row mb-4">
              <div className="form-outline">
                <input name='name' value={fBack.name} type="text" id="form3Example1" onChange={hangleChange} placeholder='Full name' className="form-control" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="form-outline ">
                <textarea id="w3reviewform3Example3" name='comment' value={fBack.comment} onChange={hangleChange} placeholder='Enter Message' rows="1" cols="50" className="form-control"> </textarea>
              </div>
            </div>

            <button type="submit" className="btn px-3 btn-block mb-4 text-white" onClick={handlefedbacks} style={{ backgroundColor: color }}>Give Feedback</button>
            {/* </form> */}
            <div style={{ color: "green" }}>
              {success ? "Subbmitted Successfully" : ""}
            </div>
            <div style={{ color: "red" }}>
              {error ? "Error in Submission" : ""}
            </div>
          </div>
        </div>}
      </div>

    </>
  )
}

export default Feedback