import React from 'react';
import '../assets/style.css';


function Aboutus({ aboutUsApi, homecolor, services }) {

    let color = homecolor.color_code;
    let background = '';
    let result = aboutUsApi?.description.split('\r\n')

    if (color) {
        background = `linear-gradient(to right, ${color}  6px, transparent 4px) 0 0,linear-gradient(to right, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${color}  6px, transparent 4px) 0 0,linear-gradient(to bottom, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to top, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to top, ${color}  6px, transparent 4px) 100% 100%`
    };
    return (
        <>
            <div id='aboutus' className='m-5  col-lg-5 col-md-8 col-sm-10 col-xs-12 col-12s m-auto my-3 ' style={{ backgroundColor: "white" }}>
                {background && <div className='p-3 px-4' style={{
                    background: background,
                    backgroundRepeat: " no-repeat",
                    backgroundSize: "60px 100px",
                    backgroundColor: "white"
                }}>
                    <div style={{ marginTop: "20px" }}>
                        <div className='container fw-bold fs-1 m-auto pb-2 pl-2 ml-2 w-lg-50 w-100' style={{ color: color, marginBottom: "25px", marginTop: "15px" }}>About us</div>
                    </div>
                    <div className='justify-content-center m-auto'>
                        <div>
                            <div className='row w-lg-50 w-100 m-auto'>
                                <div className='col-lg-5 col-md-5 col-12'>
                                    <div className='fw-bold '>Company name  </div>
                                </div>
                                <div className=' col-lg-6 col-md-6 col-12'>
                                    <div className='px-lg-0 px-md-0 px-3'><span className='fw-bold '>: </span>{aboutUsApi?.title} </div>
                                </div>
                            </div>
                            {/* <div className='row w-lg-50 w-100 m-auto'>
                                <div className='col-lg-5 col-md-5 col-12'>
                                    <div className='fw-bold'>Year of Est. </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='px-lg-0 px-md-0 px-3'><span className='fw-bold '>: </span> 2008 </div>
                                </div>
                            </div> */}

                            <div className='row w-lg-50 w-100 m-auto mb-3'>
                                <div className='col-lg-5 col-md-5 col-12'>
                                    <div className='fw-bold'>Nature Of Business </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12 px-lg-0 px-md-0 px-4'>
                                    {services.map((data, key) => (
                                        <div key={key}><span className='fw-bold'> : </span> {data.service_name}</div>
                                    ))}

                                </div>
                            </div>
                            <div className='row w-lg-50 w-100 m-auto my-2'>
                                <div className='col-6'>
                                    <div className='fw-bold'> Our Specialities : </div>
                                </div>
                            </div>
                            {/* <div className='row w-lg-50 w-100 m-auto my-4 ml-3'>
                            <div >
                                <div><li> काम ही पूजा & पूजा ही काम </li></div>
                            </div>
                        </div> */}
                            <div className='row w-lg-50 w-100 m-auto my-2 '>
                                <div style={{ marginBottom: "30px" }}>
                                    <div className='text-secondary'>
                                        {result}
                                    </div>
                                    {/* <div className='fw-bold mt-3'>
                                        Address :
                                    </div>
                                    <div className='text-secondary pb-4 lh-1'>
                                    <small>
                                        {result.splice(2, 3).map((data) => (
                                                <span> {data} </span>   
                                        ))}
                                    </small>
                                    </div>
                                    <div className='fw-bold mt-3'>
                                        Contact :
                                    </div>
                                    <div className='text-secondary lh-2'>
                                        {result.splice(1).map((data) => (
                                            <small>
                                                <div className='pb-1'> {data}</div>
                                            </small>
                                        ))}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
}

export default Aboutus