import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import * as React from 'react';
import '../assets/caro.css';
import '../assets/style.css';


export default function PDF({ data, homecolor }) {
  let color = homecolor.color_code;
  let background = '';
  if (color) {
    background = `linear-gradient(to right, ${color}  6px, transparent 4px) 0 0,linear-gradient(to right, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${color}  6px, transparent 4px) 0 0,linear-gradient(to bottom, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to top, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to top, ${color}  6px, transparent 4px) 100% 100%`
  };

  return (
    <>
      <div id='pdf' className='m-5 mt-3 col-lg-5 col-md-8 col-sm-10 col-xs-12 col-12 m-auto ' style={{ backgroundColor: "white" }}>
        {background && <div className='p-3' style={{
          background: background,
          backgroundRepeat: " no-repeat",
          backgroundSize: "60px 100px",
          backgroundColor: "white"
        }}>
          <div style={{ marginTop: "20px" }}>
            <div className='container fw-bold fs-1 m-auto pb-2 pl-2 ml-2 w-lg-50 w-100' style={{ color: color, marginBottom: "25px", marginTop: "15px" }}>PDF</div>
          </div>
          <div className='my-3'>
            <Box sx={{ width: "auto", height: " auto", overflowY: 'scroll' }}>
              <ImageList variant="masonry" cols={2} gap={20}>
                {data.pdf?.map((item, key) => (
                  <ImageListItem key={key} style={{ border: '2px solid #ff7300', borderRadius: '10px', }} gap={20} onClick={() => {
                    window.open(item.FileSrc)
                  }}
                  >
                    {item.FileSrc !== "" && item.FileSrc !== null &&
                      <>
                        <object data={item.FileSrc} style={{ borderRadius: '10px' }} onClick={() => {
                          window.open(item.FileSrc)
                        }} type="application/pdf" width="100%" height="100%">
                        </object>
                        <div className='mt-2 pdf-heading text-center'>
                          <Typography component={'span'} variant={'body1'} color="inherit" className=' text'>
                            {item.title}
                          </Typography>
                        </div>
                      </>
                    }
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          </div>
        </div>}
      </div>
    </>
  );
}
