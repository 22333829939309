import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Button } from "@mui/material";
import React from "react";
import "../assets/style.css";

function Footer({ apidata, homecolor }) {
  console.log(apidata?.feature_details != 0);
  const date = new Date();
  const year = date.getFullYear();
  let color = homecolor.color_code;
  let background = "";
  if (color) {
    background = `linear-gradient(to right, ${color}  6px, transparent 4px) 0 0,linear-gradient(to right, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to left, ${color}  6px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${color}  6px, transparent 4px) 0 0,linear-gradient(to bottom, ${color}  6px, transparent 4px) 100% 0,linear-gradient(to top, ${color}  6px, transparent 4px) 0 100%,linear-gradient(to top, ${color}  6px, transparent 4px) 100% 100%`;
  }
  return (
    <>
      <div className="m-lg-5 m-lg-auto p-1  text-black col-lg-5  col-md-7 col-sm-8 col-12">
        <div className="d-flex">
          <div className="flex-1">
            <a href='https://play.google.com/store/apps/details?id=com.saraup' target={'_blank'}> <Button><img src={require("../assets/images/Playstore.png")} className='cardImage' /></Button></a>
          </div>
          <div className="flex-1">
            <a href='https://apps.apple.com/us/app/saraup/id1578229036' target={'_blank'}><Button><img src={require("../assets/images/Appstorenew.png")} className=' cardImage' />

            </Button></a>
          </div>
        </div>
      </div>
      <div
        className="m-lg-5 col-lg-5  col-md-7 col-sm-8 col-12 m-lg-auto p-1  text-black"
        style={{ backgroundColor: 'rgb(71 56 52 / 40%)'}}
      >

        <div
          className=" mb-5 pt-2 pb-4 text-white fw-bold text-center"
          style={{ backgroundColor: "  #7bb858ed6" }}
        >
          {/* @2022 Powered by Digital Esy */}
          <div>
            Powered by <a href="https://saraup.com/" target='_blank'>SaraUp</a>
          </div>
        </div>
      </div>
      <div className="bg-secondary ">
        <div
          className="row bg-white m-lg-0 m-md-0 p-2 px-3 text-center overflow-scroll  footerdiv"
          style={{ flexWrap: "nowrap", color: color }}
        ><div className="col">
            <a className="footerankertag" style={{ color: color }} href="#home">
              <HomeIcon /> <br /> Home{" "}
            </a>
          </div>


          {
            (apidata?.business_card_details != 0) ? (apidata?.service_details != 0) ? <div className="col">
              <a
                className="footerankertag"
                style={{ color: color }}
                href="#aboutus"
              >
                <BusinessCenterIcon /> <br /> ABOUT{" "}
              </a>
            </div> : " " : ""
          }

          {
            (apidata?.service_details != 0) ? <div className="col">
              <a
                className="footerankertag"
                style={{ color: color }}
                href="#product"
              >
                <InventoryIcon /> <br /> PRODUCT
              </a>{" "}
            </div> : ""
          }
          {
            (apidata?.payment_details != 0) ? <div className="col">
              <a
                className="footerankertag"
                style={{ color: color }}
                href="#payment"
              >
                <PaymentsIcon /> <br /> PAYMENT{" "}
              </a>
            </div> : ""
          }

          {
            (apidata?.galleries_details != 0) &&
            <div className="col">
              <a
                className="footerankertag"
                style={{ color: color }}
                href="#gallery"
              >
                <ContactPageIcon /> <br /> GALLERY{" "}
              </a>{" "}
            </div>
          }
          {apidata?.business_card_details != 0 && apidata?.business_card_details.videos.length > 0 && <div className="col">
            <a
              className="footerankertag"
              style={{ color: color }}
              href="#video"
            >
              <ContactPageIcon /> <br /> Video{" "}
            </a>
          </div>}
          {apidata?.business_card_details != 0 && apidata?.business_card_details.pdf.length > 0 && <div className="col">
            <a
              className="footerankertag"
              style={{ color: color }}
              href="#pdf"
            >
              <ContactPageIcon /> <br /> PDF{" "}
            </a>
          </div>}
          {/* <div className="col">
            <a
              className="footerankertag"
              style={{ color: color }}
              href="#videos"
            >
              <VideocamIcon /> <br /> VIDEOS{" "}
            </a>{" "}
          </div> */}
          {/* <div className="col">
            <a
              className="footerankertag"
              style={{ color: color }}
              href="#feedback"
            >
              <StarIcon /> <br /> FEEDBACK{" "}
            </a>
          </div>
          <div className="col">
            <a
              className="footerankertag"
              style={{ color: color }}
              href="#enquiryform"
            >
              <ChatBubbleIcon /> <br /> ENQUIRY{" "}
            </a>{" "}
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Footer;
