import axios from "axios";
import React, { useState } from "react";
import "./App.css";
import Aboutus from "./components/Aboutus";
import EnquiryForm from "./components/EnquiryForm";
import Feedback from "./components/Feedback";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Image from "./components/Image";

import Payments from "./components/Payments";
import Product from "./components/Product";
import { appConfig } from "./config/appconfig";
import FooterSaraup from "./saraup-component/Footer";
import Gallery from "./saraup-component/Gallery";
import HomeSaraup from "./saraup-component/Home";
import ImageSaraup from "./saraup-component/Image";
import NotFound from "./saraup-component/NotFound";
import PDF from "./saraup-component/PDF";

function App() {
  const [apidata, setApidata] = useState([]);
  const [colorCode, setColorCode] = useState([]);
  const pathname = window.location.pathname;
  const [vendorStatus, setVendorStatus] = useState(false);

  React.useEffect(() => {
    if (appConfig.apiUrl.includes("saraup")) {
      axios
        .get(`${appConfig.apiUrl}digitalcard${pathname}`)
        .then((response) => {
          if (response?.data?.ResponseCode === 200) {
            setApidata(response);
            setColorCode({ color_code: "#ff7300" });
          } else if (response?.data?.ResponseCode === 400) {
            setVendorStatus(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get(`${appConfig.apiUrl}vcard${pathname}`)
        .then((response) => {
          if (response?.data?.ResponseCode === 200) {
            if (
              response.data == "" ||
              response.data == undefined ||
              response.data == null
            ) {
              window.location.href = "https://vcard.digitalesy.com/";
            }
            setApidata(response.data);
            setColorCode(response.data.data);
          } else if (response?.data?.ResponseCode === 400) {
            setVendorStatus(true);
          }
        })
        .catch((error) => {
          window.location.href = "https://vcard.digitalesy.com/";
        });
    }
  }, []);

  const bgimage = apidata?.business_card_details?.cover_image_src;
 console.log(apidata?.data?.service_details)
  return vendorStatus ? (
    <div
    style={{
      backgroundImage: `url(${bgimage})`,
      backgroundSize: " 100% 20%",
    }}
  >
    <NotFound />
    </div>
  ) : (
    typeof apidata?.data !== "undefined" && (
      <>
        <div
          style={{
            backgroundImage: `url(${bgimage})`,
            backgroundSize: " 100% 20%",
          }}
        >
          {appConfig.apiUrl.includes("saraup") ? (
            <React.Fragment>
              <HomeSaraup
                homeApi={apidata?.data?.business_card_details}
                home={apidata?.data}
                carddetails={apidata?.data.feature_details}
                facebook={apidata?.data?.shareComponent}
                homecolor={colorCode}
              />
              {apidata?.data?.galleries_details.length > 0 && (
                <ImageSaraup
                  imageApi={apidata?.data?.galleries_details}
                  homecolor={colorCode}
                />
              )}
              {apidata?.data?.business_card_details &&
                apidata?.data?.business_card_details.videos.length > 0 && (
                  <Gallery
                    data={apidata?.data?.business_card_details}
                    homecolor={colorCode}
                  />
                )}
              {apidata?.data?.business_card_details &&
                apidata?.data?.business_card_details.pdf.length > 0 && (
                  <PDF
                    data={apidata?.data?.business_card_details}
                    homecolor={colorCode}
                  />
                )}
              <FooterSaraup apidata={apidata?.data} homecolor={colorCode} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {apidata?.data?.business_card_details != 0 ? (
                // apidata?.data?.feature_details != 0 ? (
                  <Home
                    homeApi={apidata?.data?.business_card_details}
                    carddetails={apidata?.data?.feature_details}
                    facebook={apidata?.data?.shareComponent}
                    homecolor={colorCode}
                  />
                
              ) : (
                ""
              )}

              {apidata?.data?.business_card_details != 0 ? (
                // apidata?.data?.service_details != 0 ? (
                  <Aboutus
                    aboutUsApi={apidata?.data?.business_card_details}
                    homecolor={colorCode}
                    services={apidata?.data?.service_details}
                  />
                // ) : (
                //   ""
                // )
              ) : (
                ""
              )}

              {apidata?.data?.service_details != 0 ? (
                <Product
                  productApi={apidata?.data?.service_details}
                  homecolor={colorCode}
                />
              ) : (
                ""
              )}

              {apidata?.data?.payment_details != 0 ? (
                <Payments
                  paymentApi={apidata?.data?.payment_details}
                  homecolor={colorCode}
                />
              ) : (
                ""
              )}
              {apidata?.data?.galleries_details != 0 ? (
                <Image
                  imageApi={apidata?.data?.galleries_details}
                  homecolor={colorCode}
                />
              ) : (
                ""
              )}

              {/* <Videos homecolor={colorCode} /> */}
              <Feedback
                feedbackApi={apidata?.data?.feedback}
                homecolor={colorCode}
              />
              <EnquiryForm
                EnquiryFormApi={apidata?.data?.business_card_details}
                homecolor={colorCode}
              />
              <Footer apidata={apidata?.data} homecolor={colorCode} />
            </React.Fragment>
          )}
        </div>
      </>
    )
  );
}

export default App;
